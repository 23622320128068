// When the user clicks on the button, scroll to the top of the document
function scroll_to_top() {
    jQuery('body,html').animate({
        scrollTop: 0
    }, 800);
}

function check_anchor_links() {
    if(window.location.hash.length > 0) {
        window.scrollTo(0, jQuery(window.location.hash).offset().top);
    }
}