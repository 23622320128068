function init_banner() {
    jQuery('.js-banner-slider').each(function() {
        var $this = jQuery(this);
        $this.find('.items').PISliderJS({
            points: $this.find('.points'),
            loop: true,
            drag: true,
            gutter: 0,
            columns: 1,
            timing: 'ease-in-out',
            duration: 500
        });
    });
}